<template>
  <PublicLayout>
    <div class="login-form-wrapper">
      <div
        class="login-form-content"
        v-if="currentRoute.name === 'login'"
      >
        <div class="login-form-header">
          Log into your account
        </div>
        <div class="login-form-header-description">
          Enter your details to log into your account
        </div>
        <form
          class="login-form"
          @submit.prevent="login"
        >
          <div class="login-form-label">
            Username
          </div>
          <div class="login-form-input">
            <input
              type="text"
              placeholder="Insert your username"
              v-model="formData.username"
              @input="resetErrorMessage"
            >
          </div>
          <div class="login-form-label">
            Password
          </div>
          <div class="login-form-input password">
            <input
              :type="!passwordVisible ? 'password' : 'text'"
              placeholder="Insert your password"
              v-model="formData.password"
              @input="resetErrorMessage"
            >
            <div
              class="visibility-button"
              @click="togglePassVisibility"
            >
              <EyeIcon v-if="!passwordVisible" />
              <EyeOffIcon v-else />
            </div>
          </div>
          <div
            class="login-error-message"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </div>
          <div class="login-form-footer">
            <div class="forgot-password">
              Forgot your password?
              <span
                class="forgot-password-btn"
                @click="resetPassword"
              >
                Reset password
              </span>
            </div>
            <button
              type="submit"
              :class="['login-btn', {'disabled': !!errorMessage || loginProcessing}]"
              :disabled="!!errorMessage || loginProcessing"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      <router-view :username="formData.username" />
    </div>
  </PublicLayout>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';
import PublicLayout from './PublicLayout';

export default {
  components: {
    EyeIcon,
    EyeOffIcon,
    PublicLayout,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const currentRoute = useRoute();

    const passwordVisible = ref(false);
    const togglePassVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };
    const errorMessage = computed((() => store.getters.loginErrorMessage));
    const resetErrorMessage = () => {
      if (errorMessage.value) store.dispatch('loginErrorMessage', '');
    };
    const changePasswordAttributes = computed(() => store.getters.changePasswordAttributes);
    const resetPassword = () => {
      router.push({ name: 'password-reset' });
    };

    const formData = ref({
      username: '',
      password: '',
    });

    const loginProcessing = ref(false);

    const login = () => {
      if (loginProcessing.value) return;
      resetErrorMessage();
      loginProcessing.value = true;
      store.dispatch('login', formData.value)
        .then(() => {
          loginProcessing.value = false;
          router.push({ name: 'events' });
        })
        .catch(() => { loginProcessing.value = false; });
    };

    const goToPasswordChange = () => {
      router.push({ name: 'password-change' });
    };

    watch(() => changePasswordAttributes.value, (newValue) => {
      if (newValue) goToPasswordChange();
    });

    return {
      formData,
      login,
      passwordVisible,
      togglePassVisibility,
      errorMessage,
      resetErrorMessage,
      changePasswordAttributes,
      loginProcessing,
      resetPassword,
      currentRoute,
    };
  },
};
</script>

<style lang="scss">

.login-form-wrapper {
  .login-form-content {
    display: flex;
    flex-direction: column;

    .login-form-header {
      color: #191414;
      font-family: Poppins;
      font-size: 19.8px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }

    .login-form-header-description {
      color: #6D6D6D;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 40px;
    }

    .login-form {
      display: flex;
      flex-direction: column;

      .login-form-label {
        overflow: hidden;
        color: #6D6D6D;
        text-overflow: ellipsis;
        font-family: Rubik;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 2px;
      }

      .login-form-input {
        display: flex;
        height: 44px;
        margin-bottom: 16px;

        &.password {
          position: relative;
        }

        input {
          height: 100%;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
          border: none;
          outline: none;
          border-radius: 4px;
          border: 1px solid #F0F0F0;
          background: #FFF;
          box-shadow: none;
          outline-offset: 1px;
          padding: 0 8px;

          &::placeholder {
            color: #CDCDCD;
          }

          &:hover {
            border-color: #CDCDCD;
          }

          &:focus-visible {
            border-color: #CDCDCD;
            outline: 2px solid #A2BFFF;
          }
        }

        .visibility-button {
          height: 43px;
          width: 43px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;

          svg {
            height: 20px;
            width: 20px;
            color: #000;
          }
        }
      }

      .login-error-message {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 16px;
        color: #FF2E2D;
      }

      .login-form-footer {
        width: 100%;
        height: 44px;
        display: flex;
        flex-direction: column;

        .forgot-password {
          color: #6D6D6D;
          font-family: Rubik;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: #191414;
            font-family: Rubik;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .login-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #003C3C;
          border-radius: 6px;
          cursor: pointer;
          color: #fff;
          font-size: 14px;
          width: 100%;
          height: 44px;
          min-height: 44px;
          max-height: 44px;
          margin-top: 40px;
          padding: 8px;

          &.disabled {
            cursor: not-allowed;
            border-radius: 4px;
            background: #F0F0F0;
            color: #A9A9A9;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .login-form-wrapper {
    .login-form-content {
      .login-form {
        .login-error-message {
          margin-bottom: 0;
        }

        .login-form-footer {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          margin-top: 24px;

          .login-btn {
            width: 80px;
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>
